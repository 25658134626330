<section class="py-5">
    <div class="container">
        <h2>Outdoor Plants </h2>
        <p>Outdoor plants fall into a number of different categories. Broad categories include trees, shrubs, and succulents. While many of these plants can be grown indoors, they thrive outdoors with natural sun and shade, nutrient-rich earth and ample water.</p>
        <div class="row  mt-5">
            <div class="col-md-9 plant-gallery">
              <div class="row">
                <div class="col-md-3 mb-4" *ngFor="let img of images">
                  <img class="img-fluid img-thumbnail" [src]="img" alt="">
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="p-3 border shadow-sm">
                <h5 class="mb-3">Categories </h5>
                <ul class="list-dotted pl-3">
                  <li><a routerLink="./">Forestry Plants</a></li>
                  <li><a routerLink="./">Ornamentals</a></li>
                </ul>
              </div>
            </div>
          </div>
    </div>
</section>