import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-floweringPage',
  templateUrl: './flower.component.html',
})
export class FlowerPageComponent implements OnInit {

  images = [
    'assets/images/FlowerPlants.jpg',
    'assets/images/FlowerPlants2.jpg',
    'assets/images/FlowerPlants3.jpg',
    'assets/images/flowerPlants4.jpg',
    'assets/images/FlowerPlants5.jpg',
    'assets/images/FlowerPlants6.jpg',
    'assets/images/FlowerPlants7.jpg',
    'assets/images/FlowerPlants8.jpg',
    'assets/images/FlowerPlants9.jpg',
    'assets/images/FlowerPlants10.jpg',
    'assets/images/FlowerPlants11.jpg',
    'assets/images/FlowerPlants12.jpg',
    'assets/images/FlowerPlants13.jpg',
    'assets/images/FlowerPlants15.jpg',
    'assets/images/FlowerPlants16.jpg',
    'assets/images/FlowerPlants17.jpg',
    'assets/images/IndoorPlants13.jpg',
    'assets/images/FlowerPlants18.jpg',
    'assets/images/GulabFlower.jpg',
    'assets/images/Lili.jpg',



  ]

  ngOnInit() { }

}
