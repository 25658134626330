import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';//added

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})

export class HomePageComponent implements OnInit {
  constructor(private router: Router) {

  }//added

  banners = [
    { img: 'assets/images/banner1.jpg', title: 'The Home of Plants & Services ', btn: 'Know More', link: '/contact' },
    { img: 'assets/images/s4.jpg', title: 'The Home of Plants & Services', btn: 'Know More', link: '/contact' },
    { img: 'assets/images/s1.jpg', title: 'The Home of Plants & Services', btn: 'Know More', link: '/contact' }
  ]
  services = [
    { img: 'assets/images/Floweplantbanner.jpg', title: 'Flowering Plants', link: '/flower' },
    { img: 'assets/images/indoorplants3.jpg', title: 'Indoor Plants', link: '/indoor' },
    { img: 'assets/images/s3.jpg', title: 'Outdoor Plants', link: '/outdoor' }
  ]

  ngOnInit() { }
  ClickOnImage(link) {
    console.log('hi')
    this.router.navigate([link])
  }//added




}
