<section class="py-5">
    <div class="container">
        <h2>About Us</h2>
        <p>Established in 1993, our family run wholesale nursery occupies a seven acre site, in Mahemau distic Amethi Uttarpradesh. Happygarth is a great place to visit when you're looking for fresh ideas, plant advice or any of landscaping solution. You'll find extensive combinations of exceptional quality plants and trees in a variety of sizes to suit every budget and experienced people who can help you. In addition to the plants & trees, we also offer delivery and planting services.</p>

        <hr class="my-5">

        <h4>Our Mission</h4>
        <p>To provide fresh, Organic, modern and innovative planting to make people happy. Awareness of our planet and its environmental concerns are also at the forefront of our minds and as such we strive to promote sustainability as much as possible and really hope you like our approach and occasional eccentricity.
            People's health is only from Healthy Environments, we are ready to do anything live of Healthy Enviromentag whether it is organic farming or organic plantation. 
            We want to make organic plants available to everyone at cheap prices and to make them aware about organic farming.</p>
        <hr class="my-5">
    </div>
</section>