import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AppHeaderComponent { 

  toggleMenu() {
    let target = document.getElementById('navbarSupportedContent');
    target.classList.remove('show')
  }

}
