import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutPageComponent } from './pages/aboutpage/aboutpage.component';
import { ContactPageComponent } from './pages/contactpage/contactpage.component';
import { HomePageComponent } from './pages/homepage/homepage.component';
import { InDoorPageComponent } from './pages/indoorpage/indoorpage.component';
import { OutDoorPageComponent } from './pages/outdoorpage/outdoorpage.component';
import { FlowerPageComponent } from './pages/FloweringPage/flower.component';
import { AccessoriesPageComponent } from './pages/accessoriesPage/accessories.component';

const routes: Routes = [
  {path: '', component: HomePageComponent},
  {path: 'about', component: AboutPageComponent},
  {path: 'indoor', component: InDoorPageComponent},
  {path: 'outdoor', component: OutDoorPageComponent},
  {path: 'contact', component: ContactPageComponent},
  {path: 'flower', component: FlowerPageComponent},
  {path: 'accessories', component: AccessoriesPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
