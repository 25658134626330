import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outdoorpage',
  templateUrl: './outdoorpage.component.html',
})
export class OutDoorPageComponent implements OnInit {

  images = [
    'assets/images/s1.jpg',
    'assets/images/s2.jpg',
    'assets/images/s3.jpg',
    'assets/images/s4.jpg',
    'assets/images/Ourdoor14.jpeg',
    'assets/images/Outdoor12.jpeg',
    'assets/images/Outdoor13.jpeg',
    'assets/images/Outdoor15.jpeg',
    'assets/images/Outdoorlnats11.jpeg',
    'assets/images/CoconutPlants1.jpg',
    'assets/images/DatePalm1.jpg',
    'assets/images/guava1.jpg',
    'assets/images/Outdoor17.jpg',
    'assets/images/Outdoor16.jpg',
    'assets/images/Outdoor15.jpeg',
    'assets/images/Outdoorlnats11.jpeg',
    'assets/images/OutdoorPlants.jpg',
    'assets/images/OutdoorPlants1.jpg',
    'assets/images/OutdoorPlants2.jpg',
    'assets/images/OutdoorPlants3.jpg',
    'assets/images/OutdoorPlants4.jpg',
    'assets/images/OutdoorPlants5.jpg',
    'assets/images/PlumPlants.jpg',
    'assets/images/PlumPlants2.jpg',
    'assets/images/BottleBrush.jpg',
    'assets/images/Mornga.jpg',
    'assets/images/ArjunPlants.jpg',
    'assets/images/RoyalPalm.jpg',
    'assets/images/Kadam.jpg',
    'assets/images/Ashoka.jpg',
    'assets/images/Whitelogan_Outdoor.jpg',
    'assets/images/Alestonia_Outdoor.jpg',
    'assets/images/Alestonia2_Outdoor.jpg',
    'assets/images/Areca_Outdoor.jpg',
    'assets/images/Arjun_Outdoor.jpg',
    'assets/images/ArjunPlants.jpg',
    'assets/images/Ashoka_Outdoor.jpg',
    'assets/images/Baheda_Outdoor.jpg',
    'assets/images/Bamboo_Outdoor.jpg',
    'assets/images/BanbooPlants.jpg',
    'assets/images/Coconut_Outdoor.jpg',
    'assets/images/Gulmohar_Outdoor.jpg',
    'assets/images/Jakranda_Outdoor.jpg',
    'assets/images/Jamun_Outdoor.jpg',
    'assets/images/Kadam.jpg',
    'assets/images/Kadam_Outdoor.jpg',
    'assets/images/RoyalPalm.jpg',
    'assets/images/TeakOutdoor.jpg',
    'assets/images/TissuFig_Outdoor.jpg',
    'assets/images/DragonFruit_Olutdoor.jpg',
    'assets/images/IndiaGooseberry_Outdoor.jpg',
    'assets/images/Neem.jpeg',
    'assets/images/Lemon_Outdoor.jpg',
    'assets/images/Pomgranate_Outdoor.jpg',

  ]

  ngOnInit() { }

}
