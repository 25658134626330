import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutpage',
  templateUrl: './aboutpage.component.html',
})
export class AboutPageComponent implements OnInit {

    ngOnInit() {  }

 }
