import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accessoriesPage',
  templateUrl: './accessories.component.html',
})
export class AccessoriesPageComponent implements OnInit {

    images = [
      'assets/images/accessories1.jpg',
      'assets/images/accessories2.jpg',
      'assets/images/accessories3.jpg',
      'assets/images/accessories4.jpg',
      'assets/images/accessories5.jpg',
      'assets/images/accessories6.jpg',
      'assets/images/accessories7.jpg',
      'assets/images/accessories8.jpg',
      'assets/images/accessories9.jpg',
      'assets/images/accessories10.jpg',
      'assets/images/accessories11.jpg',
      'assets/images/accessories12.jpg',
      'assets/images/accessories13.jpg',
    ]

    ngOnInit() {  }

}
