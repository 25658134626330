import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppFooterComponent } from './footer/footer.component';
import { AppHeaderComponent } from './header/header.component';
import { HomePageComponent } from './pages/homepage/homepage.component';
import { CommonModule } from '@angular/common';
import { AboutPageComponent } from './pages/aboutpage/aboutpage.component';
import { InDoorPageComponent } from './pages/indoorpage/indoorpage.component';
import { OutDoorPageComponent } from './pages/outdoorpage/outdoorpage.component';
import { ContactPageComponent } from './pages/contactpage/contactpage.component';
import { AccessoriesPageComponent } from './pages/accessoriesPage/accessories.component';
import { FlowerPageComponent } from './pages/FloweringPage/flower.component';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    HomePageComponent,
    AboutPageComponent,
    InDoorPageComponent,
    OutDoorPageComponent,
    ContactPageComponent,
    AccessoriesPageComponent,
    FlowerPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
