<header class="shadow-sm">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light bg-light p-0">
      <a class="navbar-brand" routerLink="/">
        <img align="left" src="assets/images/logo.jpg" alt=""><span><h2>Happygarth</h2></span>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li (click)="toggleMenu()" class="nav-item ml-md-3" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" routerLink="/">Home </a>
          </li>
          <li (click)="toggleMenu()" class="nav-item ml-md-3" routerLinkActive="active">
            <a class="nav-link" routerLink="/about">About Us</a>
          </li>
          <li class="nav-item dropdown ml-md-3" routerLinkActive="active">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Plants
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a (click)="toggleMenu()" class="dropdown-item" routerLink="flower">Flowering Plants</a>
              <a (click)="toggleMenu()" class="dropdown-item" routerLink="indoor">Indoor Plants</a>
              <a (click)="toggleMenu()" class="dropdown-item" routerLink="outdoor">Outdoor Plants</a>
            </div>
          </li>
          <li (click)="toggleMenu()" class="nav-item ml-md-3" routerLinkActive="active">
            <a class="nav-link" routerLink="/contact">Conatct Us</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
