<footer class="py-3 border-top">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h5>About us</h5>
                <p> We are providing the high range of quality plants at reasonable rates.
                    Professional gardening solution, High quality of landscaping services that our customers can enjoy
                    for years to come.</p>
            </div>
            <div class="col-md-4 px-md-5">
                <h5>Useful Links</h5>
                <ul class="list-unstyled">
                    <li><a routerLink="/">Home</a></li>
                    <li><a routerLink="/about">About Us</a></li>
                    <li><a routerLink="/indoor">Indoor Plants</a></li>
                    <li><a routerLink="/outdoor">Outdoor Plants</a></li>
                    <li><a routerLink="/contact">Contact Us</a></li>

                </ul>
            </div>
            <div class="col-md-4">
                <h5>Contact us</h5>
                <p class="m-0"><i class="fa fa-home"></i>Mahemau, District Amethi, Uttar Pradesh</p>
                <p class="m-0"><i class="fa fa-phone"></i> <a href="tel:+918009032639">+91 8009032639</a><a
                        href="tel:+919793676711"> , +91 9793676711</a></p>
                <p><i class="fa fa-envelope"></i><a href="mailto:happygarths@gmail.com">happygarths@gmail.com</a> </p>
                <div class="social-links">
                    <a href="https://www.facebook.com/Happygarthcom-106940391449830"><i
                            class="fab fa-facebook-f"></i></a>
                    <a
                        href="https://www.linkedin.com/in/happy-garth-419a14207?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BkmFugDeBQAK19tjht6Z1hA%3D%3D"><i
                            class="fab fa-linkedin-in"></i></a>
                    <a href="https://www.youtube.com/channel/UC6LMDPDfKRe9kIvXRON3-XA/featured"><i
                            class="fab fa-youtube"></i></a>
                </div>
            </div>
        </div>
    </div>
</footer>