<section class="py-5">
    <div class="container">
        <h2 class="mb-4">Contact Us</h2>
        <div class="row">
            <div class="col-md-7 mb-5 mb-md-0">
                <p *ngIf="isSent">Your message has been sent successfully.</p>
                <form (submit)="sendEmail($event)" *ngIf="!isSent">
                    <div class="form-row">
                        <div class="col-md-6">
                            <label for="">First Name</label>
                            <input name="first_name" type="text" class="form-control" placeholder="First Name">
                        </div>
                        <div class="col-md-6">
                            <label for="">Last Name</label>
                            <input name="last_name" type="text" class="form-control" placeholder="Last Name">
                        </div>
                    </div>
                    <div class="form-row my-4">
                        <div class="col-md-6">
                            <label for="">Email Id</label>
                            <input name="email" type="email" class="form-control" placeholder="Email Id">
                        </div>
                        <div class="col-md-6">
                            <label for="">Phone Number</label>
                            <input name="phone" type="text" class="form-control" placeholder="Phone Number">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="">Message</label>
                        <textarea name="message" class="form-control" placeholder="Message"></textarea>
                    </div>
                    <button type="submit" class="btn btn-success">Submit</button>
                </form>
            </div>
            <div class="col-md-5">
                <p><i class="fa fa-home"></i> Mahemau, District Amethi, Uttar Pradesh</p>
                <p><i class="fa fa-phone"></i><a href="tel:+918009032639">+91 8009032639</a><a href="tel:+919793676711">
                        , +91 9793676711</a></p>
                <p><i class="fa fa-envelope"></i><a href="mailto:happygarths@gmail.com">happygarths@gmail.com</a></p>
                <div class="social-links">
                    <a href="https://www.facebook.com/Happygarthcom-106940391449830"><i
                            class="fab fa-facebook-f"></i></a>
                    <a
                        href="https://www.linkedin.com/in/happy-garth-419a14207?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BkmFugDeBQAK19tjht6Z1hA%3D%3D"><i
                            class="fab fa-linkedin-in"></i></a>
                    <a href="https://www.youtube.com/channel/UC6LMDPDfKRe9kIvXRON3-XA/featured"><i
                            class="fab fa-youtube"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57071.525014485946!2d81.679709607733!3d26.4470811150362!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399a4f457701db55%3A0x84244181e3410e39!2z4KSu4KS54KSVIOCkqOCksOCljeCkuOCksOClgCAo4KSq4KWM4KSn4KS24KS-4KSy4KS-KQ!5e0!3m2!1sen!2sin!4v1615014705034!5m2!1sen!2sin"
    width="100%" height="350" frameborder="0" class="border-0" allowfullscreen="" aria-hidden="false"
    tabindex="0"></iframe>