<section class="py-5">
  <div class="container">
    <h2>Indoor Plants </h2>
    <p>There are a variety of plants that thrive in indoor environments. Plants that require a low amount of light and water 
      to thrive are typically known as indoor plants. 
      People are often surprised on how beneficial indoor plants are. Not only do plants clean the air, but they also reduce noise levels and stress in the workplace.</p>
    <p>Improve Air ,Breathing Easier, Improving Health, Sharpening Focus
      When you breathe, your body takes in oxygen and releases carbon dioxide.
     During photosynthesis, plants absorb carbon dioxide and release oxygen. This opposite pattern of gas use makes plants and people natural partners. Adding plants to interior spaces can increase oxygen levels.At night, photosynthesis ceases, and plants typically respire like humans, absorbing oxygen and releasing carbon dioxide. A few plants –orchids, succulents and epiphytic bromeliads –do just the opposite, taking in carbon dioxide and releasing oxygen.</p>
    <div class="row  mt-5">
      <div class="col-md-12 plant-gallery">
        <div class="row">
          <div class="col-md-3 mb-4" *ngFor="let img of images">
            <img class="img-fluid img-thumbnail" [src]="img" alt="">
          </div>
        </div>
      </div>
      <!-- <div class="col-md-3">
        <div class="p-3 border shadow-sm">
          <h5 class="mb-3">Categories </h5>
          <ul class="list-dotted pl-3">
            <li><a routerLink="/">Flower Decor</a></li>
            <li><a routerLink="/">Gardens & Yards</a></li>
            <li><a routerLink="/">Houseplants Guide</a></li>
            <li><a routerLink="/">Indoor Planters</a></li>
            <li><a routerLink="/">Planting Equipment</a></li>
            <li><a routerLink="/">Plants Stands & Trays</a></li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</section>
