import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactpage.component.html',
})
export class ContactPageComponent {

  isSent: boolean;

  sendEmail(e: Event) {
    e.preventDefault();
    console.log(e, e.target, e.target as HTMLFormElement);
    emailjs.sendForm('service_j4qi48x', 'template_x2eup2i', e.target as HTMLFormElement, 'user_e6oeCNb9HOSqnZqZx0sz0')
      .then((result: EmailJSResponseStatus) => {
        this.isSent = true;
      }, (error) => {
        console.log(error.text);
      });
  }
  

}
