import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indoorpage',
  templateUrl: './indoorpage.component.html',
})
export class InDoorPageComponent implements OnInit {

  images = [
    'assets/images/indoorplant1.jpg',
    'assets/images/IndoorPlants.jpg',
    'assets/images/indoorplants2.jpg',
    'assets/images/indoorplants3.jpg',
    'assets/images/indoorplants4.jpg',
    'assets/images/indoorplants5.jpg',
    'assets/images/indoorplants6.jpg',
    'assets/images/indoorplants10.jpg',
    'assets/images/indoorPlants7.jpg',
    'assets/images/indoorplants8.jpg',
    'assets/images/IndoorPlants11.jpg',
    'assets/images/Cactus.jpg',
    'assets/images/AloeCosmo_Indoor.jpg',
    'assets/images/Astrophytom_Indoor.jpg',
    'assets/images/CactusIndoor.jpg',
    'assets/images/Croton_Indoor.jpg',
    'assets/images/copiapoa_Indoor.jpg',
    'assets/images/DonkeyTails_Indoor.jpg',
    'assets/images/Echeveria_Indoor.jpg',
    'assets/images/EcheveriaPeacocki_Indoor.jpg',
    'assets/images/GreenNote_Indoor.jpg',
    'assets/images/IndoorPlants12.jpg',
    'assets/images/Melocactus_Indoor.jpg',
    'assets/images/QueenVictoria_Indoor.jpg',
    'assets/images/SilverDust_Indoor.jpg',
    'assets/images/StringOfPearlsIndoor.jpg',
    'assets/images/TableKamini_Indoor.jpg',
    'assets/images/TurkishCap_Indoor.jpg',
    'assets/images/NewIndoor.jpg',
    'assets/images/LuckyBamboo_Outdoor.jpg',
    'assets/images/Aloecasia_Indoor.jpg',
    'assets/images/Philodendron_indoor.jpg',
    'assets/images/PhilondendronYellow_indoor.jpg',
    'assets/images/BigMontera_indoor.jpg',
    'assets/images/BabuVaricated_INdoor.jpg',
    'assets/images/Calathea_Indoor.jpg',




  ]

  ngOnInit() { }

}
