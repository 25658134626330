<section class="py-5">
    <div class="container">
      <h2>Services </h2>
      <p>Agricultural and farming services provide information, consulting, equipment, and supplies to the agricultural industry. Examples include government agricultural extensions, crop brokers and shippers, meat packers, produce distributors and wholesalers, and veterinarians.</p>
      
       <h2>Accessories </h2>
      <p>Agricultural machinery relates to the mechanical structures and devices used in farming or other agriculture. There are many types of such equipment, from hand tools and power tools to tractors and the countless kinds of farm implements that they tow or operate. Diverse arrays of equipment are used in both organic and nonorganic farming. Especially since the advent of mechanised agriculture, agricultural machinery is an indispensable part of how the world is fed.</p>
     
      <div class="row  mt-5">
        <div class="col-md-9 plant-gallery">
          <div class="row">
            <div class="col-md-3 mb-4" *ngFor="let img of images">
              <img class="img-fluid img-thumbnail" [src]="img" alt="">
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="p-3 border shadow-sm">
            <h5 class="mb-3">Categories </h5>
            <ul class="list-dotted pl-3">
              <li><a routerLink="/">Farming Equipment</a></li>
              <li><a routerLink="/">Planting Equipment</a></li>
              <li><a routerLink="/">Plants Stands & Trays</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  