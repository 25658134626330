<section class="slider">
  <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let item of banners; index as index" [ngClass]="{'active': index === 0}">
        <img [src]="item.img" class="">
        <div class="carousel-caption d-none d-md-block">
          <h2 class="text-white">{{item.title}}</h2>
          <a [routerLink]="item.link" class="btn btn-primary">{{item.btn}}</a>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</section>

<section class="py-5 mb-4">
  <div class="container text-center">
    <h1>Welcome to Happygarth</h1>
    <p>The Happygarth is well worth a visit and we’ve worked hard to be able to offer a fabulous and organic selection
      of plants. We grow over 1000 plant varieties, Provide the innovative products and services for global agriculture
      & food industry. Happygarth is dedicated to native plants and organic gardening.
      Here, we provide professional solutions to people in all type organic farming in every way. </p>

    <div class="services mt-5 pt-3">
      <div class="row">
        <div class="col-md-4" *ngFor="let item of services; index as index">
          <div class="item" (click)="ClickOnImage(item.link)" style="cursor: pointer;">

            <div class="position-relative mb-3">
              <h2>{{index+1}}</h2>
              <img class="rounded-circle img-fluid" [src]="item.img" alt="">
            </div>
            <a [routerLink]="item.link" class="h5 m-0">{{item.title}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>