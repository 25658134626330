<section class="py-5">
    <div class="container">
      <h2>Flowering Plants </h2>
      <p>Although, flowering plants are very much attractive and heart-stealing because their mind-blowing fragrance and appealing appearance win the heart of each and everyone. They also have an exceptional quality which enhances its importance in everyone’s life, i.e. their medicinal quality and various health benefits. Yes, the flowering plants are quite beneficial for your health in so many ways. Some of the health benefits of keeping them in your home and office are stated below. So, take a look at the whole article and understand the plethora of health benefits of flowering plants to keep yourself fit and healthy all the time.</p>
      <p>Happygarth growing flowering plants at large scale which are Good For Your Mental Health, Reduce Your Stress Level aand very usefull to make you happy and healthy.</p>
      <div class="row  mt-6">
        <div class="col-md-12 plant-gallery">
          <div class="row">
            <div class="col-md-3 mb-4" *ngFor="let img of images">
              <img class="img-fluid img-thumbnail" [src]="img" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  